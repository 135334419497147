.req-third-party-copy-grid {
    display: grid;
    gap: 10px;
    margin-bottom: 30px;
}

.req-third-party-copy-grid-item-1 {
    grid-column-start: 1;
    grid-column-end: 3;
}

.req-third-party-copy-grid-item-2 {
    grid-column-start: 1;
    grid-column-end: 2;
}

.req-third-party-copy-grid-item-3 {
    grid-column-start: 1;
    grid-column-end: 2;
}

/* PC Image */
.req-third-party-copy-grid-item-4 {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    place-self: end center;
    margin-left: 0;
    width: 90%;
}

/* Other Images */
.req-third-party-copy-grid-item-5 {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    place-self: end;
    margin-left: 40px;
}

/* Sub Pac Image */
.req-third-party-copy-grid-item-6 {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    place-self: start center;
    margin-left: 40px;
    width: 80%;
}

/* Flight Case Image */
.req-third-party-copy-grid-item-7 {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    place-self: start;
    margin-left: 40px;
}

.req-third-party-copy-margin {
    margin-top: 1rem;
}

@media screen and (max-width: 768px) {
    .req-third-party-copy-grid {
        margin-left: 50px;
    }
    
    .req-third-party-copy-grid-item-2 {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    
    .req-third-party-copy-grid-item-3 {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    
    /* PC Image */
    .req-third-party-copy-grid-item-4 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        place-self: start;
        margin-left: 0;
        width: 50%;
    }
    
    /* Other Images */
    .req-third-party-copy-grid-item-5 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        place-self: start;
        margin-left: 0;
    }
    
    /* Sub Pac Image */
    .req-third-party-copy-grid-item-6 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        place-self: start;
        margin-left: 0;
        width: 50%;
    }
    
    /* Flight Case Image */
    .req-third-party-copy-grid-item-7 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        place-self: start;
        margin-left: 0;
    }
}

@media screen and (max-width: 600px) {
    .req-third-party-copy-grid {
        margin-left: 10px;
    }
}