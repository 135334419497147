.products-dropdown {
  width: 10rem;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  display: block;
}

.products-dropdown.clicked {
  display: none;
}

.products-dropdown li {
  background: #1a1d21;
  cursor: pointer;
  transition: .2s ease-in;
}

.products-dropdown li a {
  text-decoration:none;
  color: #fff;
}

.products-dropdown li:hover {
  background: #4d5257;
}

.products-dropdown-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 16px;
  font-family: 'Roobert';
  font-size: 1.125rem;
}

.products-dropdown-link {
  text-decoration:none;
  color: #fff;
}

.products-dropdown-v-arrow {
  display: inline;
  width: 14px;
  margin-left: 10px;
}

ul {
  list-style-type: none;
}


@media screen and (max-width: 960px) {
  .products-dropdown {
    width: 100%;
    position: static;
    height: 60px;
    list-style: none;
    text-align: center;
    display: block;
  }

  .products-dropdown-item {
    font-size: 1.2rem;    
  padding: 20px;
  }
}