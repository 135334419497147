body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'RoobertReg';
  src: local('RoobertReg'), url(./fonts/Roobert/Roobert-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'RoobertBold';
  src: local('RoobertBold'), url(./fonts/Roobert/Roobert-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Roobert';
  src: local('Roobert'), url(./fonts/Roobert/Roobert-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'DM_SansBold';
  src: local('DM_SansBold'), url(./fonts/DM_Sans/DMSans-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'DM_SansBoldItalic';
  src: local('DM_SansBoldItalic'), url(./fonts/DM_Sans/DMSans-BoldItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'DM_SansItalic';
  src: local('DM_SansItalic'), url(./fonts/DM_Sans/DMSans-Italic.ttf) format('opentype');
}

@font-face {
  font-family: 'DM_SansMedium';
  src: local('DM_SansMedium'), url(./fonts/DM_Sans/DMSans-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'DM_SansMediumItalic';
  src: local('DM_SansMediumItalic'), url(./fonts/DM_Sans/DMSans-MediumItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'DM_Sans';
  src: local('DM_Sans'), url(./fonts/DM_Sans/DMSans-Regular.ttf) format('opentype');
}

.under-construction {
  color: #292e33;
  text-align: center;
  margin-top: 50px;
}