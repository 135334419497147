.working-at-bg {
  background-color: #292e33;
}

.working-at-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.working-at-hero {
  color: #fff;
  max-width: 70%;
  margin-left: 15%;
}

h1.working-at-title {
  font-size: 3rem;
  line-height: 110%;
  font-weight: 600;
  padding-top: 80px;
  margin-bottom: 30px;
}

.working-at-description {
  margin-top: 30px;
  padding-bottom: 10px;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #fff;
}

.working-at-img-row {
  display: flex;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 30px 30px 30px;
}

.working-at-img {
    width: 32%;
    border-radius: 12px;
}

@media screen and (max-width: 768px) {
    .working-at-hero {
        max-width: 100%;
        margin-left: 30px;
        margin-right: 30px;
      }

      .working-at-img-row {
        padding: 30px 30px 50px 30px;
      }

      .working-at-img {
        width: 100%;
        margin: 15px 0px;
        border-radius: 12px;
    }



}
