.stretchAnimation {
    background-color: #82dc36;
    margin-bottom: 12px;
    line-height: 5px;    
    width: 0px;
    animation-name: stretchAnimation;
    animation-duration: 600ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 200ms;
}

@keyframes stretchAnimation {
    from {width: 0px;}
    to {width: 100px;}
  }