.about-section-dark-bg {
  background-color: #292e33;
}

.about-section-heading {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-top: 40px;
  font-size: 3rem;
  line-height: 110%;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.about-section-container {
  max-width: 1280px;
  margin: auto;
  padding: 0 30px 50px 30px;
}

.about-section-subtitle {
  max-width: 800px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 32px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: #b3b7bc;
}

.about-section-btn-container {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.about-section-join {
  color: #fff;
  text-decoration: none;
  padding: 20px 48px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.about-section-join:hover {
  opacity: 0.6;
  transition: all 0.3s ease;
}

.about-section-img-large {
  border-radius: 12px;
  width: 85%;
  margin: auto;
  display: block;
}

@media screen and (max-width: 768px) {
  .about-section-container {
    padding-left: 45px;
    padding-right: 45px;
  }

  .about-section-btn-container {
    flex-direction: column;
  }

  .about-section-join {
    margin-top: 20px;
  }

  .about-section-img-large {
    width: 100%;
  }
}
