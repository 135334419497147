.omni-arena-select-bg {
    background-color: #fff;    
}

.omni-arena-select-container {
    padding: 120px 20px 50px 20px;
    max-width: 1280px;
    margin: auto;
}

.omni-arena-select-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
}

.omni-arena-select-business-img-wrap {
    border-radius: 12px;
    background-image: linear-gradient(0deg, rgba(0,0,0,0.9026961126247374) 15%, rgba(0,0,0,0.6449930313922444) 35%, rgba(255,255,255,0) 45%), url("../../../../public/images/oa_select_business.jpg");
    background-color: #292e33;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 550px;
    height: 620px;
}

.omni-arena-select-players-img-wrap {
    border-radius: 12px;
    background-image: linear-gradient(0deg, rgba(0,0,0,0.9026961126247374) 15%, rgba(0,0,0,0.6449930313922444) 30%, rgba(255,255,255,0) 45%), url("../../../../public/images/oa_select_players.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 550px;
    height: 620px;
}

.omni-arena-select-text-container {
    position: relative;
    height: 100%;
    margin: auto 50px auto 20px;
}

.omni-arena-select-text-wrap {
    position: absolute;
    bottom: 20px;
}

.omni-arena-select-topline{
    font-size: 1.5rem;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: auto;
    margin-bottom: 16px;
    color: #82dc36;
}

.omni-arena-select-heading {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 2.2rem;
    line-height: 110%;
    font-weight: 600;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .omni-arena-select-business-img-wrap {
        width: 100%;
        height: 650px;
    }

    .omni-arena-select-players-img-wrap {
        width: 100%;
        height: 650px;
    }

    .omni-arena-select-flex {
        flex-direction: column;
    }

    .omni-arena-select-text-container {
        margin: auto 20px auto 20px;
    }
}

@media screen and (max-width: 600px) {
    .omni-arena-select-players-img-wrap {
        background-position: right;
    }
}