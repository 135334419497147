.sdk-form-container {
    margin: 30px 0 0 0;
}

.sdk-form-label {
    font-size: 1rem;
    margin-bottom: 3px;
}

.sdk-form-input {
    font-family: inherit;
    font-size: 15px;
    width: 100%;
    border: 1px solid #ABB0B2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #343434;
    background-color: #fff;
    box-sizing: border-box;
    height: 32px;
    padding: 0px 0.4em;
    display: inline-block;
    margin: 0 0 15px 0;
    vertical-align: top;
}

.sdk-form-input-faded {
    color: #989898;
}

.sdk-form-submit {
    margin-top: 10px;
    width: 100%;
    border-radius: 12px;
    height: 60px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    padding: 12px 48px;
    font-size: 18px;
    font-weight: 700;
    background-color: #82dc36;
    color: #1a1d21;
    border: none;
}

.sdk-form-submit-faded {
    margin-top: 10px;
    width: 100%;
    border-radius: 12px;
    height: 60px;
    outline: none;
    border: none;
    white-space: nowrap;
    padding: 12px 48px;
    font-size: 18px;
    font-weight: 700;
    background-color: #6ec822;
    color: #1a1d21;
    border: none;
    transition: all 0.8s ease-in;
}

.sdk-form-submit:hover {
    transition: all 0.3s ease-out;
    background: #6ec822;
}

.sdk-form-success {
    margin-top: 10px;
    font-size: 1.2rem;
}