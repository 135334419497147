.press-hero-bg {
  background-color: #292e33;
  background-image: url(../../../../public/images/background-circles_1px_33opacity.png);
  background-repeat: no-repeat;
  background-position-x: 75%;
  background-position-y: 70px;
  background-size: 700px;
}

.press-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.press-news-cards-display {
  margin: 40px 30px 40px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 40px;
}

.press-hero {
  margin-top: 70px;
  color: #fff;
  max-width: 75%;
  margin-left: 12.5%;
  padding-bottom: 50px;
}

h1.press-title {
  font-size: 4.5rem;
  line-height: 110%;
  font-weight: 600;
  padding-top: 80px;
  margin-bottom: 30px;
}

h2.press-subtitle {
  margin-top: 30px;
  padding-bottom: 50px;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 32px;
}

.press-card-container {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }

.press-card-row {
  display: flex;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  align-content: stretch;
  padding: 60px 30px 30px 30px;
}

a.press-link {
  color: #fff;
}

a.press-link:link {
  color: #fff;
}

a.press-link:visited {
  color: #fff;
}

a.press-link:hover {
  color: #b3b7bc;
}

.press-pagination-center {
  text-align: center;
}

.press-pagination {
  display: inline-block;
  margin-bottom: 20px;
}

.press-pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  margin: 0 4px;
}

.press-pagination a.active {
  background-color: #6EC822;
  color: white;
  border-radius: 5px;
}

.press-pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .press-hero-bg {
    background-position-x: 0%;
  }

  .press-news-cards-display {    
    display: flex;
    flex-direction: column;
  }

  .press-card-row {
    padding: 0px 30px 0px 30px;
  }

  .press-card-row:first-child {
    padding: 30px 30px 0px 30px;
  }

  .press-card-row:last-child {
    padding: 0px 30px 30px 30px;
  }

  h1.press-title {
    padding-top: 60px;
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 600px) {
  .press-hero {
    margin-top: 70px;
    color: #fff;
    max-width: 100%;
    margin-left: 30px;
    margin-right: 30px;
  }
}
