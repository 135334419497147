.desired-pos-dark-bg {
    background-color: #292e33;
  }

  .desired-pos-container {
    max-width: 1280px;
    margin: auto;
    padding: 0 30px 40px 30px;
  }
  
  .desired-pos-heading {
    margin-bottom: 10px;
    padding-top: 100px;
    font-size: 2.5rem;
    line-height: 110%;
    font-weight: 400;
    color: #fff;
    text-align: center;
  }
  
  .desired-pos-subtitle {
    max-width: 900px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 32px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #b3b7bc;
  }

  .desired-pos-link {
    text-decoration: none;
    color: #8dc63f;
  }
  
  .desired-pos-link:hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }
  
  @media screen and (max-width: 768px) {
    .desired-pos-container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  