.developers-hero-bg {
    background-color: #292e33;
    background-image: url(../../../../public/images/background-circles_1px_33opacity.png);
    background-repeat: no-repeat;
    background-position-x: 75%;
    background-position-y: 70px;
    background-size: 700px;
  }
  
  .developers-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .developers-news-cards-display {
    margin: 40px 30px 60px 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 20px;
    row-gap: 40px;
  }
  
  .developers-hero {
    margin-top: 70px;
    color: #fff;
    max-width: 78%;
    margin-left: 10%;
    padding-bottom: 20px;
  }
  
  h1.developers-title {
    font-size: 4.5rem;
    line-height: 110%;
    font-weight: 600;
    padding-top: 80px;
    margin-bottom: 30px;
  }
  
  h2.developers-subtitle {
    margin-top: 30px;
    padding-bottom: 50px;
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 32px;
  }
  
  .developers-card-container {
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
    }
  
  .developers-card-row {
    display: flex;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    align-content: stretch;
    padding: 60px 30px 30px 30px;
  }
  
  a.developers-link {
    color: #fff;
  }
  
  a.developers-link:link {
    color: #fff;
  }
  
  a.developers-link:visited {
    color: #fff;
  }
  
  a.developers-link:hover {
    color: #b3b7bc;
  }
  
  @media screen and (max-width: 768px) {
    .developers-hero-bg {
      background-position-x: 0%;
    }
  
    .developers-news-cards-display {
      flex-direction: column;
    }
  
    .developers-card-row {
      padding: 0px 30px 0px 30px;
    }
  
    .developers-card-row:first-child {
      padding: 30px 30px 0px 30px;
    }
  
    .developers-card-row:last-child {
      padding: 0px 30px 30px 30px;
    }
  
    h1.developers-title {
      padding-top: 60px;
      font-size: 3.5rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .developers-hero {
      margin-top: 70px;
      color: #fff;
      max-width: 100%;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  