.perks-section-bg {
  background-color: #fff;
}

.perks-section-container {
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
}

.perks-section-heading {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-top: 40px;
  font-size: 3rem;
  line-height: 110%;
  font-weight: 600;
  color: #292e33;
  text-align: center;
}

.perks-section-grid-container {
  display: grid;
  grid-template-columns: .5fr 4fr .5fr 4fr;
  grid-auto-flow: column;
  grid-column-gap: 15px;
  grid-row-gap: 50px;
  justify-content: center;
  margin: 60px 30px 90px 30px;
}

.perks-grid-text {
  grid-column: 2;
}

.perks-grid-text-2 {
  grid-column: 4;
}

.perks-grid-icon {
  place-self: start end;
  grid-column: 1;
}

.perks-grid-icon-2 {
  place-self: start end;
  grid-column: 3;
}

.perks-checkmark {
  width: 2rem;
  margin-right: .5rem;
}

.perks-title {
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

.perks-description {
  font-size: 1rem;
  line-height: 1.3;
  color: #4D5257;
}

@media screen and (max-width: 768px) {
  
  .perks-section-grid-container {
    display: grid;
    grid-template-columns: .5fr 4fr;
    /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  }

  .perks-grid-text-2 {
    grid-column: 2;
  }

  .perks-grid-icon-2 {
    place-self: start end;
    grid-column: 1;
  }
}
