.legal-list-bg {
    background-color: #fff;
  }
  
  .legal-list-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 30px;
  }
  
  .legal-list-hero {
    color: #292e33;
    max-width: 70%;
    margin-left: 15%;
  }
  
  h1.legal-list-title {
    font-size: 3rem;
    line-height: 110%;
    font-weight: 600;
    padding-top: 80px;
    margin-bottom: 30px;
  }
  
  .legal-list-description {
    margin-top: 30px;
    padding-bottom: 60px;
    font-size: 1.25rem;
    line-height: 1.4;
    color: #292e33;
  }
  
  .legal-list-number-container {    
    border-bottom: 2px solid #292e33;
    padding-bottom: 15px;
    margin-bottom: 25px;
  }

  .legal-list-number-container-last { 
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
  
  .legal-list-number {
      font-size: 1.375rem;
      margin-bottom: 25px;
      font-weight: 600;
  }
  
  .legal-list-number-category {
      font-size: 1.2rem;
      color: #4D5257;
      margin-bottom: 5px;
  }
  
  .legal-list-number-link {
      text-decoration: none;
      color: #262626;
  }
  
  .legal-list-number-link:hover {
      opacity: .6;
    transition: all 0.2s ease;
  }
  
  .legal-list-right-arrow {
      display: inline;
      padding-left: 5px;
      width: 20px;
  }
  
  
  @media screen and (max-width: 768px) {
    .legal-list-hero {
      max-width: 100%;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  