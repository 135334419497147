.careers-one-bg {
  background-color: #fff;
}

.careers-one-dark-bg {
  background-color: #eaedef;
}

.careers-one-gray-bg {
  background-color: #eaedef;
  padding-top: 40px;
  padding-bottom: 40px;
}

.careers-one-text {
  color: #292e33;
  padding: 30px 0 20px 0;
  font-family: DM_Sans;
}

.careers-one-text-extra-padding {
  padding-top: 40px;
}

.careers-one-container {
  max-width: 1280px;
  margin: auto;
  padding: 50px 45px;
}

.careers-one-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.careers-one-row {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
}

.careers-one-col-01 {
  margin-bottom: 15px;
  /* flex: 1; */
  max-width: 50%;
  /* flex-basis: 50%; */
}

.careers-one-col-02 {
  margin-bottom: 15px;
  padding: 0 30px 0 30px;
  /* flex: 1; */
  max-width: 50%;
  /* flex-basis: 50%; */
}

.careers-one-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.careers-one-topline {
  font-size: 1.5rem;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: auto;
  margin-bottom: 16px;
}

.careers-one-heading {
  margin-top: 20px;
  font-size: 3rem;
  line-height: 110%;
  font-weight: 600;
  color: #292e33;
}

.careers-one-text-dark {
  color: #292e33;
}

.careers-one-subtitle {
  max-width: 440px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 32px;
}

.careers-one-subtitle-no-margin {
  max-width: 440px;
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 32px;
}

.careers-one-img-wrapper {
  max-width: 555px;
}

.careers-one-img {
  border: 0;
  border-radius: 12px;
  vertical-align: middle;
  display: inline-block;
  margin-top: 0;
  margin-right: 0;
  padding-right: 0;
}

@media screen and (max-width: 936px) {
  .careers-one-flex {
    flex-direction: column;
  }

  .careers-one-col-01 {
    max-width: 100%;
    /* flex-basis: 100%; */
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 30px;
  }

  .careers-one-col-02 {
    max-width: 87%;
    /* flex-basis: 100%; */
    padding-top: 20px;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .careers-one-container {
    padding: 50px 30px;
  }

  .careers-one-col-02 {
    max-width: 100%;
    /* flex-basis: 100%; */
  }

  .careers-one-text-wrapper {
    padding-bottom: 0px;
  }

  .careers-one-text {
    padding: 30px 0 0 0;
  }
}
