.omni-sub-container {
    clear: left;
    font-style: inherit;
    margin-top: 15px;
    padding: 0 20px 10px 20px;
}

.omni-sub-input {
    font-family: inherit;
    font-size: 15px;
    width: 70%;
    border: 1px solid #ABB0B2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #343434;
    background-color: #fff;
    box-sizing: border-box;
    height: 32px;
    padding: 0px 0.4em;
    display: inline-block;
    margin: 0;
    vertical-align: top;
}

.omni-sub-margin {
    margin-right: 10px;
}

button.omni-sub-button {
    color: #1a1d21;
    background-color: #82dc36;
    transition: all .23s ease-in-out 0s;
    font-size: 15px;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    letter-spacing: .03em;
    box-sizing: border-box;
    height: 32px;
    line-height: 32px;
    padding: 0 18px;
    display: inline-block;
    margin: 0;
}

button.omni-sub-button:hover {
    background-color: #6ec822;
    transition: all 0.2s ease-out;
    cursor: pointer;
}

.omni-sub-status {
    margin-top: 16px;
}

.omni-sub-status_red {
    font-size: 1rem;
    font-family: inherit;
    margin-top: 15px;
    color: #F13642;
}
.omni-sub-status_white {
    font-size: 1rem;
    font-family: inherit;
    margin-top: 15px;
    color: #fff;
}

.omni-sub-status_green {
    font-size: 1rem;
    font-family: inherit;
    margin-top: 15px;
    color: #82CF00;
}

#linkRemove a {
    display:none;
}

@media screen and (max-width: 768px) {
    .omni-sub-container {
        width: 100%;
    }

    .omni-sub-margin {
        width: 100%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    button.omni-sub-button {
        padding: 0;
        clear: both;
        width: 100%;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .omni-sub-status_red {
        text-align: center;
    }
    
    .omni-sub-status_white {
        text-align: center;
    }
    
    .omni-sub-status_green {
        text-align: center;
    }
}