.open-pos-bg {
  background-color: #fff;
}

.open-pos-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.open-pos-hero {
  color: #292e33;
  max-width: 70%;
  margin-left: 15%;
}

h1.open-pos-title {
  font-size: 3rem;
  line-height: 110%;
  font-weight: 600;
  padding-top: 80px;
  margin-bottom: 30px;
}

.open-pos-description {
  margin-top: 30px;
  padding-bottom: 60px;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #292e33;
}

.open-pos-job-container {    
  border-bottom: 2px solid #292e33;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.open-pos-job {
    font-size: 1.375rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.open-pos-job-category {
    font-size: 1.2rem;
    color: #4D5257;
    margin-bottom: 15px;
}

.open-pos-job-link {
    text-decoration: none;
    color: #262626;
}

.open-pos-job-link:hover {
    opacity: .6;
  transition: all 0.2s ease;
}

.open-pos-right-arrow {
    display: inline;
    padding-left: 5px;
    width: 20px;
}


@media screen and (max-width: 768px) {
  .open-pos-hero {
    max-width: 100%;
    margin-left: 30px;
    margin-right: 30px;
  }
}
