.about-hero-section-bg {
    background-color: #FFF;
}

.about-hero-section-dark-bg {
    background-color: #292e33;
}

.about-hero-section-gray-bg {
    background-color: #EAEDEF;
    padding-top: 40px;
    padding-bottom: 40px;
}

.about-hero-section-text {
    color: #eaedef;
    padding: 30px 0 20px 0;
}

.about-hero-section-text-extra-padding {
    padding-top: 40px;
}

.about-hero-section-container {
    max-width: 1280px;
    margin: auto;
    padding-right: 30px;
    padding-left: 30px;
}

.about-hero-section-row {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
}

.about-hero-section-col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
}

.about-hero-section-text-wrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 0;
}

.about-hero-section-topline{
    font-size: 1.5rem;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: auto;
    margin-bottom: 16px;
}

.about-hero-section-heading {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 3rem;
    line-height: 110%;
    font-weight: 600;
    color: #fff;
}

.about-hero-section-text-dark {
    color: #292e33;
}

.about-hero-section-subtitle {
    max-width: 500px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 1.125rem;
    font-weight: 200;
    line-height: 32px;
}

.about-hero-section-subtitle-no-margin {
    max-width: 440px;
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 200;
    line-height: 32px;
}

.about-hero-section-img-wrapper {
    max-width: 555px;
}

.about-hero-section-img {
    max-width: 100%;
    border: 0;
    border-radius: 12px;
    vertical-align: middle;
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    padding-right: 0;
}

@media screen and (max-width: 768px) {
    .about-hero-section-text-wrapper {
        padding-bottom: 0px;
    }

    .about-hero-section-text {
        padding: 30px 0 0 0;
    }

    .about-hero-section-col {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
    }
}