label.omni-update-sub-modal-radio-label {
    display: block;
    margin-left: 20px;
}

input.omni-update-sub-modal-input[type="radio"] {
    display: none;
  }

  input.omni-update-sub-modal-input[type="radio"] + *::before {
    float: left;
    margin-left: -20px;
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 1rem;
    height: 1rem;
    margin-right: .6rem;
    margin-bottom: .13rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #292E33;
  }

  input.omni-update-sub-modal-input[type="radio"]:checked + *::before {
    background: radial-gradient(#82dc36 0%, #82dc36 40%, transparent 50%, transparent);
    border-color: #838485;
  }
  
  input.omni-update-sub-modal-input[type="radio"]:checked + * {
    color: #838485;
  }

.omni-update-sub-modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #000;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1000;
}

.omni-update-sub-modal.enter-done {
    opacity: 1;
    pointer-events: visible;
}

.omni-update-sub-modal-content {
    width: 500px;
    background-color: #fff;
    border-radius: 12px;
}

.omni-update-sub-modal-content-none {
    display: none;
}

.omni-update-sub-modal-header {
    padding: 30px 30px 20px 30px;
    font-size: 28px;
    text-align: left;
    margin-bottom: -35px;
}

.omni-update-sub-modal-body {
    padding: 10px 30px;
    text-align: left;
}

.omni-update-sub-modal-footer {
    padding: 0px 8px 20px 8px;
}

.close-omni-update-sub-modal {
    position: relative;
    left: 98%;
    top: -50px;
    width: 30px;
}

.background-green-omni-update-sub-modal {
    background-color: #82dc36;
    margin-bottom: 12px;
    line-height: 5px;    
    width: 0px;
    margin-left: 30px;
    animation-name: stretch;
    animation-duration: 800ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 600ms;
}

.omni-update-sub-modal-radio-container {
    margin-top: 7px;
    margin-left: 30px;
    padding-left: 15px;
}

.omni-update-sub-modal-radio-option {
    margin-bottom: 10px;
}

.omni-update-sub-modal-radio-label {
    font-size: 1.1rem;
    cursor: pointer;
}

@keyframes stretch {
    from {width: 0px;}
    to {width: 100px;}
  }

  @keyframes fade {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

@media screen and (max-width: 500px) {
    .omni-update-sub-modal-content {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .omni-update-sub-modal-footer {
        padding: 0 10px 20px 10px;
    }
}

@media screen and (max-width: 470px) {
    .omni-update-sub-modal-footer {
        padding: 0 0 20px 10px;
    }

    .omni-update-sub-modal-radio-label {
        font-size: 1rem;
    }

}
