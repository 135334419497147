.home-hero-section-bg {
  background: linear-gradient(180deg, rgba(41,46,51,1) 93%, rgba(255,255,255,0) 93%);
}

.home-hero-section-text-dark {
  color: #292e33;
  padding: 40px 0;
}

.home-hero-section-display {
  display: block;
}

.home-hero-section-display-mobile {
  display: none;
}

.home-hero-section-display-mobile-sm {
  display: none;
}

.home-hero-section-text-light {
  color: #eaedef;
  padding: 40px 0;
}

.home-hero-section-text-green-nopad {
  color: #82cf00;
}

.home-hero-section-text-light-nopad {
  color: #eaedef;
}

.home-hero-section-container {
  max-width: 1280px;
  margin: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.home-hero-section-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.home-hero-section-col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home-hero-section-col-text {
  position: absolute;
  top: 15%;
  margin-bottom: 15px;
  margin-left: 10px;
  flex: 1;
  max-width: 100%;
  flex-basis: 60%;
  z-index: 15;
}

.home-hero-section-text-wrapper {
  max-width: 540px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.home-hero-section-text-dark {
  color: #292e33;
}

.home-hero-section-heading {
  margin-top: 80px;
  margin-bottom: 30px;
  font-size: 4.5rem;
  line-height: 110%;
  font-weight: 600;
  color: #fff;
}

.home-hero-section-subtitle {
  max-width: 440px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 32px;
}

.home-hero-section-copy {
  max-width: 440px;
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: 100;
  color: #82cf00;
  line-height: 25px;
}

.home-hero-video-position {
  z-index: 0;
  -webkit-filter: brightness(80%);
  filter: brightness(80%);
}

.home-hero-bg-video {
  position: absolute;
  top: 7%;
  left: 0%;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.home-hero-video-overlay {
  position: relative;
  max-height: 900px;
  min-height: 600px;
  height: 100vh;
  top: -50px;
  z-index: 10;
  overflow: hidden;
  background: linear-gradient(195deg, transparent, rgba(0, 0, 0, 0.9));
}

@media screen and (max-width: 1367px) {
  .home-hero-section-bg-dark {
    background-position-x: 80%;
  }
}

/* @media screen and (max-width: 1300px) {
  .home-hero-bg-video {
    left: -15%;
  }
} */

@media screen and (max-width: 950px) {
  .home-hero-video-overlay {
    max-height: 750px;
    height: 100vh;
  }

  .home-hero-section-text-wrapper {
    padding-top: 50px;
  }

  .home-hero-bg-video {
    left: -20%;
  }
}

@media screen and (max-width: 768px) {
  .home-hero-section-text-wrapper {
    padding-bottom: 0px;
  }

  .home-hero-section-col-text {
    max-width: 85%;
    margin-left: 15px;
  }

  .home-hero-section-bg-dark {
    background-image: url("../../public/images/omniplayer_hero1_gradient.png");
  }

  .home-hero-section-text-wrapper {
    padding-top: 0;
  }

  .home-hero-bg-video {
    left: -65%;
  }
}

@media screen and (max-width: 570px) {
  .home-hero-section-bg-dark {
    background-position-x: 40px;
  }

  .home-hero-bg-video {
    left: -90%;
  }
}

@media screen and (max-width: 500px) {
  .home-hero-section-display {
    display: none;
  }

  .home-hero-section-display-mobile {
    display: block;
  }

  .home-hero-bg-video {
    top: 6%;
    left: initial;
    right: -5%;
  }
}

@media screen and (max-width: 453px) {
  .home-hero-section-bg-dark {
    background-position-x: 50%;
  }

  .home-hero-section-heading {
    font-size: 3.75rem;
  }

  .home-hero-section-col-text {
    margin-left: 0;
    top: 110px;
  }
}

@media screen and (max-width: 400px) {
  .home-hero-bg-video {
    right: -20%;
  }
}

@media screen and (max-width: 376px) {
  .home-hero-section-heading {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 351px) {
  .home-hero-section-heading {
    font-size: 3rem;
  }
}

@media screen and (max-width: 350px) {
  .home-hero-section-display-mobile {
    display: none;
  }

  .home-hero-section-display-mobile-sm {
    display: block;
  }

  .home-hero-bg-video {
    top: 7%;
    right: -5%;
  }
}
