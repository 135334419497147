.patents-hero-bg {
    background-color: #292e33;
    background-image: url(../../../../public/images/background-circles_1px_33opacity.png);
    background-repeat: no-repeat;
    background-position-x: 75%;
    background-position-y: 70px;
    background-size: 700px;
  }
  
  .patents-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .patents-news-cards-display {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 40px 30px 60px 30px;
  }
  
  .patents-hero {
    margin-top: 70px;
    color: #fff;
    max-width: 75%;
    margin-left: 12.5%;
    padding-bottom: 20px;
  }
  
  h1.patents-title {
    font-size: 4.5rem;
    line-height: 110%;
    font-weight: 600;
    padding-top: 80px;
    margin-bottom: 30px;
  }
  
  h2.patents-subtitle {
    margin-top: 30px;
    padding-bottom: 50px;
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 32px;
  }
  
  .patents-card-container {
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
    }
  
  .patents-card-row {
    display: flex;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    align-content: stretch;
    padding: 60px 30px 30px 30px;
  }
  
  a.patents-link {
    color: #fff;
  }
  
  a.patents-link:link {
    color: #fff;
  }
  
  a.patents-link:visited {
    color: #fff;
  }
  
  a.patents-link:hover {
    color: #b3b7bc;
  }
  
  @media screen and (max-width: 768px) {
    .patents-hero-bg {
      background-position-x: 0%;
    }
  
    .patents-news-cards-display {
      flex-direction: column;
    }
  
    .patents-card-row {
      padding: 0px 30px 0px 30px;
    }
  
    .patents-card-row:first-child {
      padding: 30px 30px 0px 30px;
    }
  
    .patents-card-row:last-child {
      padding: 0px 30px 30px 30px;
    }
  
    h1.patents-title {
      padding-top: 60px;
      font-size: 3.5rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .patents-hero {
      margin-top: 70px;
      color: #fff;
      max-width: 100%;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  