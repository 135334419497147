.about-us-hero-bg {
    background-color: #292e33;
    background-image: url(../../../../public/images/background-circles_1px_33opacity.png);
    background-repeat: no-repeat;
    background-position-x: 75%;
    background-position-y: 70px;
    background-size: 700px;
}

.about-us-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.about-us-hero {
    margin-top: 70px;
    color: #fff;
    max-width: 75%;
    margin-left: 12.5%;
}

.about-img-quote {
    display: grid;
    grid-template-columns: auto auto;
    padding-bottom: 50px;
    gap: 5px;
}

.about-headshot-img {
    border-radius: 100%;
    width: 120px;
    grid-row: 1 / span 2;
}

.about-quote {
    font-size: 1.5rem;
    margin-left: 20px;
    line-height: 2rem;
    font-style: italic;
}

.about-quote-title {
    font-size: 1.125rem;
    margin-left: 20px;
    color: #82dc36;
}

h1.about-us-title {
    font-size: 4.5rem;
    line-height: 110%;
    font-weight: 600;
    padding-top: 80px;
    margin-bottom: 30px;
}

h2.about-us-subtitle {
    margin-top: 30px;
    padding-bottom: 50px;
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 32px;
}

.about-us-card-container {
    background-color: #fff;
}

.about-us-card-row {
    display: flex;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;    
    flex-wrap: wrap;
    align-content: stretch;
    padding: 60px 30px 30px 30px;
}

@media screen and (max-width: 768px) {
    .about-us-hero-bg {
        background-position-x: 0%;
    }

    .about-us-card-row {
        padding: 0px 30px 0px 30px;
    }

    .about-us-card-row:first-child {
        padding: 30px 30px 0px 30px;
    }

    .about-us-card-row:last-child {
        padding: 0px 30px 30px 30px;
    }

    h1.about-us-title {
        padding-top: 60px;
        font-size: 3.5rem;
    }
}


@media screen and (max-width: 600px) {
    .about-us-hero {
        margin-top: 70px;
        color: #fff;
        max-width: 100%;
        margin-left: 30px;
        margin-right: 30px;
    }

    .about-img-quote {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .about-quote {
        margin-left: 0;
    }
    
    .about-quote-title {
        margin-left: 0;
    }

    .about-headshot-img {
        margin: 10px auto 20px auto;
    }
}