.navbar {
  background: #292e33;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  position: fixed;
  width: 100%;
  top: 0;
  /* top: 1.775em;  Add this line to add announcement bar */
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  max-width: 1920px;
  padding-right: 40px;
  padding-left: 40px;
}

.navbar-logo {
  width: 115px;
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: initial;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-margin {
  margin-bottom: 0px;
}

.nav-item-dropdown-width {
  width: 139px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  cursor: pointer;
}

.nav-links:hover {
  color: #b3b7bc;
  transition: all 0.3s ease;
}

.nav-links-op {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  cursor: pointer;
}

.nav-links-op:hover {
  opacity: .6;
  transition: all 0.3s ease;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 70px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #292e33;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 1.4rem;
    font-size: 1.4rem;
    font-weight: 100;
    width: 100%;
    float: none;
    display: block;
    position: relative;
  }

  .nav-links-op {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 1.4rem;
    font-size: 1.4rem;
    font-weight: 100;
    width: 100%;
    /* display: table; */
    float: none;
    display: block;
  }

  .nav-item {
    width: 100%;
    display: block;
  }

  .nav-margin {
    margin-bottom: 130px;
  }

  .nav-item:hover {
    border: none;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10%, 60%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}
