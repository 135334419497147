.ov-resources-copy-container {
    max-width: 960px;
    margin: 50px auto 50px auto;
    color: #292e33;
  }
  
  h1.ov-resources-copy-title {
    font-size: 3rem;
    /* text-align: center; */
    /* padding-top: 30px; */
    padding-bottom: 20px;
  }
  
  h2.ov-resources-copy-header {
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }

  .ov-resources-copy-header-light {
    font-weight:400;
    display: inline;
  }

  .ov-resources-copy-margin-top {
    margin-top: 2.5rem;
  }

  .ov-resources-copy-margin-top-light {
    margin-top: 1.5rem;
  }

  .ov-resources-copy-button-hero {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
  }

  h3.ov-resources-copy-subheader {
      padding-bottom: 1rem;
  }
  
  p.ov-resources-copy-body {
    font-size: 1rem;
    padding-bottom: 1rem;
    font-family: DM_Sans;
  }

  p.ov-resources-copy-body-italic {
    font-size: 1rem;
    padding-bottom: 1rem;
    font-family: DM_SansItalic;
  }

  a.ov-resources-copy-link {
    color: #4d5257;
  }

  a.ov-resources-copy-link:link {
    color: #4d5257;
  }

  a.ov-resources-copy-link:visited {
    color: #4d5257;
  }

  a.ov-resources-copy-link:hover {
    color: #b3b7bc;
  }
  
  .ov-resources-copy-indent {
      margin-left: 1rem;
  }
  
  .ov-resources-copy-list-indent {
      margin-left: 2rem;
  }
  
  a.ov-resources-copy-link-gray {
    text-decoration: none;
    color: #4d5257;
  }
  
  a.ov-resources-copy-link-gray:hover {
    text-decoration: none;
    color: #b3b7bc;
  }
  
  ol.ov-resources-copy-list-lower-alpha {
      list-style-type: lower-alpha;
  }
  
  ul.ov-resources-copy-list-disc {
      list-style-type:disc;
  }

  li.ov-resources-copy-margin {
    margin-bottom: .5rem;
  }

  img.ov-resources-copy-img-wrapper {
    max-width: 100%;
    margin-top: .5rem;
  }

  .ov-resources-copy-player-container {
    max-width: 700px;
    margin-bottom: 1.5rem;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .ov-resources-flex {
    display: flex;
    gap: 1rem;
    padding-bottom: 2rem;
    flex-direction: row;
  }

  .ov-resources-flex:last-of-type {    
    padding-bottom: 0;
  }

  .ov-resources-flex-no-pad {
    display: flex;
    gap: 1rem;
    flex-direction: row;
  }

  .ov-resources-copy-download-img {
    width: 70%;
    margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 12px;
  }
  
  @media screen and (max-width: 1280px) {
    .ov-resources-copy-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .ov-resources-copy-player-container {
      width: 100%;
    }

    .ov-resources-flex {
        flex-direction: column;
        gap: .5rem;
      }

    .ov-resources-flex-no-pad {
        flex-direction: column;
        gap: 0;
      }

    .ov-resources-copy-download-img {
      width: 100%;
      margin-bottom: 0;
    }
  }
  