.dropdown {
    display: inline-block;
    cursor: pointer;    
    width: 93%;
}

.dropdown__content {
    display: none;
    position: relative;
}

.dropdown--active .dropdown__content {
    display: block;
}

ul {
    list-style-type: none;
}