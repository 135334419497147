.footer-background {
  background-color: #292e33;
}

.footer-hr {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  border-width:0;
  background-color:#4D5257;
  display: none;
}

.footer-container {
  padding: 60px 40px;
  display: flex;
  flex: 1;
  margin: auto;
  max-width: 1280px;
}

.footer-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.footer-row-60 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
  justify-content: space-between;
}

.footer-row-40 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 40%;
  justify-content: space-between;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-column-66 {
  display: flex;
  flex-direction: column;
  width: 66%;
}

.footer-column-50 {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.footer-column-33 {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.footer-column-25 {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.footer-subdomain-col {
  padding-top: 7px;
}

.footer-subdomain-links {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}

.footer-page-links-col {
  padding-top: 10px;
}

.footer-page-links {
  color: #B3B7BC;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
}

.footer-link-white {
  text-decoration:none;
  color: #FFFFFF;
}

.footer-link-gray {
  text-decoration:none;
  color: #B3B7BC;
}

.footer-sign-up-header {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 16px;
}

.footer-sign-up {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
}

.footer-sign-up-terms {
  color: #B3B7BC;
  font-size: 12px;
  font-weight: 100;
  margin-top: 18px;
}

.footer-sign-up-social {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.footer-social-icon-link {
  color: #fff;
  font-size: 24px;
}

img.footer-social-icon-img {
  border-radius: 0;
  margin-right: 10px;
  vertical-align: middle;
}

.footer-subscribe {
  font-size: 1.3rem;
  color: #B3B7BC;
  line-height: 1.6rem;
  cursor: pointer;
  transition: 0.3s ease-in;
  filter: brightness(1);
  margin-bottom: 12px;
}

.footer-subscribe:hover {
  transition: 0.3s ease-in;
  filter: brightness(1.45);
}

.footer-subscribe-img {
  display: inline;
  margin-left: 10px;
  vertical-align: initial;
}

.footer-subscribe-hr-line {
  width: 87%;
  border-top: 1px solid #4D5257;
  border-right: hidden;
  border-bottom: hidden;
  border-left: hidden;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-copyright {
  color: #B3B7BC;
  font-size: 12px;
  font-weight: 100;
  margin-top: 30px;
  margin-bottom: 0;
}

.footer-display-1280 {
  display: block;
}

.footer-display-768 {
  display: none;
}

.footer-display-768-border {
  display: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom: 1px solid #4D5257;
}

.footer-text-padding {
  padding-bottom: 10px;
}

.footer-v-arrow-down {
  float: right;
  display: inline;
  width: 14px;
  margin-top: 14px;
}

@media screen and (max-width: 768px) {
  .footer-hr {
    display: block;
  }

  .footer-subscribe-hr-line {
    width: 94%;
  }

  .footer-column-25, .footer-column-33, .footer-column-50, .footer-column-66 {
    width: 100%;
  }

  .footer-copyright {
    display: none;
  }

  .footer-sign-up-header {
    margin-top: 25px;
  }

  img.footer-social-icon-img {
    width: 28px;
  }

  .footer-sign-up-social {
    margin-top: 40px;
    display: block;
  }

  .footer-display-1280 {
    display: none;
  }

  .footer-display-768 {
    display: block;
  }

  .footer-display-768-border {
    display: block;
  }
}