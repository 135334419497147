*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roobert', sans-serif;
}

:root {
  --form-control-color: #8dc63f;
}

.fade-up {
  width: 100%;
  top: 50%;
  opacity: 0%;
  animation: fadeslideUp 2s;
  animation-fill-mode: forwards;
}

@keyframes fadeslideUp {
  0% {
    opacity: 0;
    transform: translateY(30%); 
    -ms-transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%); 
    -ms-transform: translateY(0%);
  }
}