.dev-dl-dark-bg {
    background-color: #292e33;
    /* background-color: #1a1d21; */
  }
  
  .dev-dl-heading {
    margin-top: 20px;
    margin-bottom: 30px;
    padding-top: 40px;
    font-size: 3rem;
    line-height: 110%;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  
  .dev-dl-container {
    max-width: 1280px;
    margin: auto;
    padding: 0 30px 40px 30px;
  }
  
  .dev-dl-subtitle {
    max-width: 900px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 32px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #b3b7bc;
  }
  
  .dev-dl-btn-container {
    max-width: 700px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 25px 15px;
    place-items: stretch;
    padding-bottom: 30px;
    justify-items: center;
  }
  
  .dev-dl-join {
    color: #fff;
    text-decoration: none;
    padding: 20px 48px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  
  .dev-dl-join:hover {
    opacity: 0.6;
    transition: all 0.3s ease;
  }
  
  .dev-dl-img-large {
    border-radius: 12px;
    width: 80%;
    margin: auto;
    display: block;
  }
  
  @media screen and (max-width: 768px) {
    .dev-dl-container {
      padding-left: 45px;
      padding-right: 45px;
      padding-bottom: 50px;
    }
  
    .dev-dl-btn-container {
      display: flex;
      flex-direction: column;
      gap: 15px 15px;
    }
  
    .dev-dl-join {
      margin-top: 20px;
    }
  
    .dev-dl-img-large {
      width: 100%;
    }
  }
  