.investors-copy-container {
    max-width: 960px;
    margin: 50px auto 20px auto;
    color: #292e33;
  }
  
  h1.investors-copy-title {
    font-size: 3rem;
    /* text-align: center; */
    /* padding-top: 30px; */
    padding-bottom: 20px;
  }
  
  h2.investors-copy-header {
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }

  .investors-copy-header-light {
    font-weight:400;
    display: inline;
  }
  
  h3.investors-copy-subheader {
      padding-bottom: 1rem;
  }
  
  p.investors-copy-body {
    font-size: 1rem;
    padding-bottom: 1rem;
    font-family: DM_Sans;
  }

  p.investors-copy-body-note {
    font-size: 1rem;
    padding-bottom: 1rem;
    font-weight: 600;
    font-family: DM_Sans;
  }

  p.investors-copy-body-italic {
    font-size: 1rem;
    padding-bottom: 1rem;
    font-family: DM_SansItalic;
  }

  a.investors-copy-link {
    color: #4d5257;
  }

  a.investors-copy-link:link {
    color: #4d5257;
  }

  a.investors-copy-link:visited {
    color: #4d5257;
  }

  a.investors-copy-link:hover {
    color: #b3b7bc;
  }
  
  .investors-copy-indent {
      margin-left: 1rem;
  }
  
  .investors-copy-list-indent {
      margin-left: 2rem;
  }
  
  a.investors-copy-link-gray {
    text-decoration: none;
    color: #4d5257;
  }
  
  a.investors-copy-link-gray:hover {
    text-decoration: none;
    color: #b3b7bc;
  }
  
  ol.investors-copy-list-lower-alpha {
      list-style-type: lower-alpha;
  }
  
  ul.investors-copy-list-disc {
      list-style-type:disc;
  }

  li.investors-copy-margin {
    margin-bottom: .5rem;
  }

  .investors-copy-player-container {
    width: 70%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .investors-form-container {
    max-width: 900px;
    margin: 20px auto 50px auto;
}

.investors-form-label {
    font-size: 1.1rem;
    margin-bottom: 3px;
}

.investors-form-input {
    font-family: inherit;
    font-size: 15px;
    width: 100%;
    border: 1px solid #ABB0B2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #343434;
    background-color: #fff;
    box-sizing: border-box;
    height: 32px;
    padding: 0px 0.4em;
    display: inline-block;
    margin: 0 0 15px 0;
    vertical-align: top;
}

.investors-form-input-faded {
    color: #989898;
}

.investors-form-submit {
    margin-top: 10px;
    width: 100%;
    border-radius: 12px;
    height: 60px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    padding: 12px 48px;
    font-size: 18px;
    font-weight: 700;
    background-color: #82dc36;
    color: #1a1d21;
    border: none;
}

.investors-form-submit-faded {
    margin-top: 10px;
    width: 100%;
    border-radius: 12px;
    height: 60px;
    outline: none;
    border: none;
    white-space: nowrap;
    padding: 12px 48px;
    font-size: 18px;
    font-weight: 700;
    background-color: #6ec822;
    color: #1a1d21;
    border: none;
    transition: all 0.8s ease-in;
}

.investors-form-submit:hover {
    transition: all 0.3s ease-out;
    background: #6ec822;
}

.investors-form-success {
    margin-top: 10px;
    font-size: 1.2rem;
}

.investors-form-img-row {
    display: flex;
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 30px 30px 30px;
  }
  
  .investors-form-img {
      width: 32%;
      border-radius: 12px;
  }
  
  @media screen and (max-width: 1280px) {
    .investors-copy-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 960px) {
    .investors-form-container {
        max-width: 90%;
    }
  }

  @media screen and (max-width: 768px) {
    .investors-copy-player-container {
      width: 100%;
    }

    .investors-form-img-row {
        padding: 0 30px 50px 30px;
      }

      .investors-form-img {
        width: 100%;
        margin: 15px 0px;
        border-radius: 12px;
    }
  }
  