:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 12px;
    height: 60px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn-primary {
    background-color: var(--primary);
    color: #1a1d21;
    border: 1px solid var(--primary);
}

.btn-medium {
    padding: 8px 20px;
    font-size: 18px;
    font-weight: 700;
}

.btn-large {
    padding: 12px 42px;
    font-size: 18px;
    font-weight: 700;
}

.btn-mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: #1a1d21;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn-wide {
    padding: 12px 48px;
    font-size: 18px;
    font-weight: 700;
}

.btn-wide-fixed {
    width: 320px;
    padding: 12px 48px;
    font-size: 18px;
    font-weight: 700;
}

.btn-full-width {
    padding: 8px;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    height: 50px;
}

.btn-large:hover, .btn-medium:hover, .btn-mobile:hover {
    transition: all 0.3s ease-out;
    background: #6ec822;
    color: #1a1d21;
}

.btn-wide:hover {
    background-color: #6ec822;
    transition: all 0.2s ease-out;
}

.btn-full-width:hover {
    background-color: #6ec822;
    transition: all 0.2s ease-out;
}

.btn-outline {
    background-color: transparent;
    color: #fff;
    height: initial;
    padding: 8px 20px;
    border: 1px solid #b3b7bc; 
    font-weight: 400;
}

.btn-outline:hover {
    background-color: #fff;
    color: #292e33;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;    
    font-weight: 400;
}

.button-right-arrow {
    display: inline-flex;
    padding-left: 5px;
    width: 12%;
}

.blue {
    background-color: #276afb;
    color: #1a1d21;
    border: none;
}

.green {
    background-color: #82dc36;
    color: #1a1d21;
    border: none;
}

.red {
    background-color: #f00946;
    color: #1a1d21;
    border: none;
}

.primary {
    background-color: #242424;
    color: #1a1d21;
    border: none;
}

.primary:hover {
    background-color: #fff;
    color: #242424;
    border: none;
}

.btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .btn {
        width: 100%;
    }

    .btn-wide {
        padding: 12px 5px;
    }
}

@media screen and (max-width: 345px) {
    .btn-wide-fixed {
        padding: 12px 20px;
    }
}