.modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #000;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1000;
}

.modal.enter-done {
    opacity: 1;
    pointer-events: visible;
}

.modal-content {
    width: 600px;
    background-color: #fff;
    border-radius: 12px;
}

.modal-header {
    padding: 30px 30px 20px 30px;
    font-size: 28px;
    text-align: left;
    margin-bottom: -40px;
}

.modal-body {
    padding: 10px 30px;
    text-align: left;
}

.modal-footer {
    padding: 0px 8px 20px 8px;
}

.close-modal {
    position: relative;
    left: 97%;
    top: -50px;
    width: 30px;
}

.background-green-modal {
    background-color: #82dc36;
    margin-bottom: 12px;
    line-height: 5px;    
    width: 0px;
    margin-left: 30px;
    animation-name: stretch;
    animation-duration: 800ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 600ms;
}

@keyframes stretch {
    from {width: 0px;}
    to {width: 100px;}
  }

  @keyframes fade {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

@media screen and (max-width: 768px) {
    .modal-content {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }

    .modal-footer {
        padding: 0px 30px 20px 30px;
    }
}