.omni-sub-modal-header {
    padding: 30px 30px 20px 30px;
    font-size: 28px;
    text-align: left;
    margin-bottom: 0;
}

.close-omni-sub-modal {
    position: relative;
    left: 92%;
    top: 38px;
    width: 30px;
}

.omni-sub-modal-footer {
    padding: 0px 8px 20px 8px;
}

@media screen and (max-width: 500px) {
    .omni-sub-modal-footer {
        padding: 0px 10px 20px 10px;
    }
}

@media screen and (max-width: 470px) {
    .omni-sub-modal-footer {
        padding: 0px 0 20px 0;
    }
}