.copy-container {
  max-width: 1280px;
  margin: 80px auto 50px auto;
  color: #292e33;
}

.copy-container-no-header {
  max-width: 1280px;
  margin: 150px auto 100px auto;
  color: #292e33;
}

h1.copy-title {
  font-size: 3rem;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 50px;
}

h2.copy-header {
  font-size: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

h3.copy-subheader {
  padding-bottom: 1rem;
}

p.copy-body {
  font-size: 1rem;
  padding-bottom: 1rem;
  font-family: DM_Sans;
}

.copy-indent {
  margin-left: 1rem;
}

.copy-list-indent {
  margin-left: 2rem;
}

.copy-underline {
  text-decoration: underline;
}

a.copy-link-gray {
  text-decoration: none;
  color: #4d5257;
}

a.copy-link-gray:hover {
  text-decoration: none;
  color: #b3b7bc;
  cursor: pointer;
}

.copy-link-to-gray {
  text-decoration: none;
  color: #4d5257;
}

.copy-link-to-gray:hover {
  text-decoration: none;
  color: #b3b7bc;
}

ol.copy-list-lower-alpha {
  list-style-type: lower-alpha;
}

li {
  font-family: DM_Sans;
}

ul.copy-list-disc {
  list-style-type: disc;
}

ul.copy-list-circle {
  list-style-type: circle;
}

sup.copy-sup {
  font-weight: 100;
  font-size: 10px;
}

.copy-table-container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.copy-table-data td,
.copy-table-data th {
  border: 1px solid #4d5257;
  padding: 8px;
}

.copy-table-data th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4d5257;
  color: white;
  letter-spacing: 1px;
  font-weight: 200;
}

@media screen and (max-width: 1280px) {
  .copy-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .copy-container-no-header {    
    margin: 110px auto 80px auto;
    padding-left: 40px;
    padding-right: 40px;
  }
}
