.careers-home-hero-dark-bg {
    background-color: #292e33;
    /* background-color: #1a1d21; */
  }
  
  .careers-home-hero-heading {
    margin-top: 40px;
    margin-bottom: 50px;
    padding-top: 40px;
    font-size: 4.5rem;
    line-height: 110%;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  
  .careers-home-hero-container {
    max-width: 1280px;
    margin: auto;
    padding: 60px 30px 40px 30px;
  }
  
  .careers-home-hero-subtitle {
    max-width: 900px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 32px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #b3b7bc;
  }
  
  .careers-home-hero-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 20px;
  }
  
  .careers-home-hero-join {
    color: #fff;
    text-decoration: none;
    padding: 20px 48px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  
  .careers-home-hero-join:hover {
    opacity: 0.6;
    transition: all 0.3s ease;
  }
  
  .careers-home-hero-img-large {
    border-radius: 12px;
    width: 80%;
    margin: auto;
    display: block;
  }
  
  @media screen and (max-width: 768px) {
  
    .careers-home-hero-btn-container {
      flex-direction: column;
    }
  
    .careers-home-hero-join {
      margin-top: 20px;
    }
  
    .careers-home-hero-img-large {
      width: 100%;
    }
  }
  