.news-card-container {
  background-color: #fff;
  border: 0px;
  box-shadow: 0px 2px 8px #adadad;
}

.news-card-img {
  width: 100%;
  /* border-bottom: solid 3px #a2ef3c; */
}

.news-card-date {
    font-size: 1rem;
    color: #4d5257;
    margin: 10px 10px 0px 10px;
}

.news-card-headline {
  font-size: 1.5rem;
  color: #292e33;
  margin: 10px 10px 10px 10px;
  line-height: 1.8rem;
}

.news-card-byline {
  font-size: 1rem;
  color: #4d5257;
  margin: 0 10px 20px 10px;
}

.news-card-link-container {
  font-size: 1rem;
  margin: 0 10px 10px 10px;
}

.news-card-link-text {
    text-decoration: none;
}

.news-card-link {
  font-size: 1rem;
  color: #4d5257;
}

.news-card-link:hover {
  color: #b3b7bc;
}

@media screen and (max-width: 768px) {
  .news-card-container {
    width: 100%;
  }
}
